import React from "react";
import { useState , useEffect} from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "font-awesome/css/font-awesome.min.css";
import "./index.css";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import Chauffeur from "./Chauffeur";
import Ripeur from "./Ripeur";
import Circuit from "./Circuit";
import Anomalie from "./Anomalie";
import Bom from "./Bom";
import Collect from "./RapportCollect";
import RapportJournalier from "./RapportJournalier";
import RapportDaffectation from "./RapportDaffectation";
import Tonnage from "./Tonnage";
import Vidage from "./Vidage";
import RapportLeveebac from "./RapportLeveebac";
import Entreprise from "./Entreprise";
import AnomaliesRapport from "./AnomaliesRapport";
import Pointage from "./Pointage";
import Config from "./Config";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import {stringify, parse} from "flatted";
import { api } from '../config/constant.js';


const getCookie = (name) => {
  const cookies = document.cookie.split("; ");
  const cookie = cookies.find((c) => c.startsWith(`${name}=`));
  return cookie ? cookie.split("=")[1] : null;
};

const Home = ({localS, toPage, isOpen, navigate}) => {
    const [drivers, setDrivers] = useState([]);
    const [ripeurs, setRipeurs] = useState([]);
    const [circuits, setCircuit] = useState([]);
    const [boms, setBoms] = useState([]);
    const [entrepriseType, setentrepriseType] = useState(localS[2] || localStorage.getItem('entrepriseType'));
    //const [entrepriseType, setentrepriseType] = useState(localStorage.getItem('entrepriseType'));
    const [entrepriseId, setentrepriseId] = useState(localS[0] || localStorage.getItem('entrepriseId'));
    //const [entrepriseId, setentrepriseId] = useState(localStorage.getItem('entrepriseId'));
    const [isLoading, setIsLoading] = useState(false);

 useEffect(() => {
     //setentrepriseType(localStorage.getItem('entrepriseType'))
    load();
    /*setTimeout(()=>{
      setentrepriseType(localS[2])
        setentrepriseId(localS[0])
    },500)
    setTimeout(()=> load(),1000)*/
  }, [localS]);

 async function load(){
   const url = entrepriseType === "1"
      ? `${api}/drivers/getAll`
      : `${api}/drivers/getAllByEntreprise`;

    const options = entrepriseType === "1"
      ? { method: "GET" }
      : {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ entrepriseId })
        };

    fetch(url, options)
      .then(response => response.json())
      .then(data => {
        setDrivers(data.data.length);
        setIsLoading(false);

      })
      .catch(error => {
        console.error("Error fetching drivers:", error);
        setIsLoading(false);
      });



      const url1 = entrepriseType === "1"
      ? `${api}/ripeurs/getAll`
      : `${api}/ripeurs/getAllByEntreprise`;

    const options1 = entrepriseType === "1"
      ? { method: "GET" }
      : {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ entrepriseId })
        };

    fetch(url1, options1)
      .then(response => response.json())
      .then(data => {
        setRipeurs(data.data.length);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching drivers:", error);
        setIsLoading(false);
      });


      const url2 = entrepriseType === "1"
      ? `${api}/circuits/getAll`
      : `${api}/circuits/getAllByEntreprise`;

    const options2 = entrepriseType === "1"
      ? { method: "GET" }
      : {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ entrepriseId })
        };

    fetch(url2, options2)
      .then(response => response.json())
      .then(data => {
        setCircuit(data.data.length);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching drivers:", error);
        setIsLoading(false);
      });

      const url3 = entrepriseType == "1"
      ? `${api}/bom/getAll`
      : `${api}/bom/getAllByEntreprise`;

    const options3 = entrepriseType === "1"
      ? { method: "GET" }
      : {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ entrepriseId })
        };

    fetch(url3, options3)
      .then(response => response.json())
      .then(data => {
        setBoms(data.data.length);
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching drivers:", error);
        setIsLoading(false);
      });


 }

  return <div className="row">

    <div className="col-lg-3 col-6">
      <div className="card custom-small-box bg-info p-3 rounded">
        <div className="card-body d-flex justify-content-between align-items-center">
            <div className="inner">
            <h3 className="mb-1">{drivers}</h3>
            <p>Chauffeurs</p>
            </div>
            <div className="icon">
            <i className="ion ion-bag" style={{ fontSize: "2rem" }}></i>
            </div>
        </div>
        <a href="#" onClick={() => toPage("chauffeurs")} className="small-box-footer d-flex align-items-center justify-content-between p-2 text-white">
            Détail <i className="fa fa-arrow-circle-right"></i>
        </a>
    </div>
    </div>

   <div className="col-lg-3 col-6">
      <div className="card custom-small-box bg-success p-3 rounded">
        <div className="card-body d-flex justify-content-between align-items-center">
            <div className="inner">
            <h3 className="mb-1">{ripeurs}</h3>
            <p>Ripeurs</p>
            </div>
            <div className="icon">
            <i className="ion ion-bag" style={{ fontSize: "2rem" }}></i>
            </div>
        </div>
        <a href="#" onClick={() => toPage("ripeurs")}  className="small-box-footer d-flex align-items-center justify-content-between p-2 text-white">
            Détail <i className="fa fa-arrow-circle-right"></i>
        </a>
    </div>
    </div>


   <div className="col-lg-3 col-6">
      <div className="card custom-small-box bg-warning p-3 rounded">
        <div className="card-body d-flex justify-content-between align-items-center">
            <div className="inner">
            <h3 className="mb-1">{circuits}</h3>
            <p>Circuits</p>
            </div>
            <div className="icon">
            <i className="ion ion-bag" style={{ fontSize: "2rem" }}></i>
            </div>
        </div>
        <a href="#" onClick={() => toPage("circuits")}  className="small-box-footer d-flex align-items-center justify-content-between p-2 text-white">
            Détail <i className="fa fa-arrow-circle-right"></i>
        </a>
    </div>
    </div>


   <div className="col-lg-3 col-6">
      <div className="card custom-small-box bg-danger p-3 rounded">
        <div className="card-body d-flex justify-content-between align-items-center">
            <div className="inner">
            <h3 className="mb-1">{boms}</h3>
            <p>Véhicules</p>
            </div>
            <div className="icon">
            <i className="ion ion-bag" style={{ fontSize: "2rem" }}></i>
            </div>
        </div>
        <a href="#" onClick={() => toPage("vehicules")} className="small-box-footer d-flex align-items-center justify-content-between p-2 text-white">
            Détail <i className="fa fa-arrow-circle-right"></i>
        </a>
    </div>
    </div>
    {/* Add more cards here */}
  </div>
};

const App = () => {
    const navigate = useNavigate()
    const [page, setPage] = useState("Accueil");
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [entreprises, setEntreprises] = useState([]);
    const [localS, setLocalS] = React.useState([]);


  const handleChange = (value) => {
    localStorage.setItem('localS', stringify(value));
    setLocalS(value); // Met à jour l'état local
};

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };


  useEffect(() => {
            fetchEntreprises();
            const aa=   localStorage.getItem('entrepriseId');
            const bb=   localStorage.getItem('entrepriseName');
            const cc=   localStorage.getItem('entrepriseType');
            let zeze=[];
            zeze.push(aa);
            zeze.push(bb);
            zeze.push(cc);
            if(!aa){
              zeze = [];
             const dd = getCookie("entrepriseId");
             const ee = getCookie("entrepriseName");
             const ff = getCookie("entrepriseType");
            zeze.push(dd);
            zeze.push(ee);
            zeze.push(ff);
              if(!dd)
                 window.location.href = '/login';

            }
            handleChange(zeze)

  }, []);

  const fetchEntreprises = () => {
    axios.get(api + "/entreprises/getAll")
      .then(response => setEntreprises(response.data.data));
  };

  const renderPage = () => {
    switch (page) {
      case "chauffeurs":
        return <Chauffeur localS={localS} />;
      case "ripeurs":
        return <Ripeur localS={localS}  />;
      case "circuits":
        return <Circuit localS={localS}  />;
      case "anomalies":
        return <Anomalie  localS={localS} />;
      case "vehicules":
        return <Bom  localS={localS} />;
      case "Rapports pointages":
        return <Pointage  localS={localS} />;
      case "Rapports anomalies":
        return <AnomaliesRapport localS={localS}  />;
      case "entreprises":
        return <Entreprise />;
      case "Rapports collects":
        return <Collect  localS={localS} />;
      case "Rapports journaliers":
        return <RapportJournalier  localS={localS} />;
      case "Rapports d'affectations":
        return <RapportDaffectation localS={localS}  />;
      case "Tonnages":
        return <Tonnage  localS={localS} />;
      case "Vidages":
        return <Vidage  localS={localS} />;
      case "Rapports levée bacs":
        return <RapportLeveebac localS={localS}  />;
        case "Config rapport d'affectation":
        return <Config localS={localS}  />;
      case "Accueil":
        return <Home  localS={localS} toPage={handlePageChange} />;
      // ...other pages
      default:
        return <Home  localS={localS} toPage={handlePageChange} />;
    }
  };

  return (
     <div className={`wrapper d-flex ${isSidebarOpen ? "sidebar-open" : "sidebar-closed"}`}>
      <Sidebar navigate={navigate} toPage={handlePageChange} isOpen={isSidebarOpen} />
      <div className="content-wrapper w-100">
        <Navbar localS={localS} handleChange={handleChange} entreprises={entreprises} navigate={navigate}  title={page.toUpperCase()} toggleSidebar={toggleSidebar} />
        <div className="content p-4">{renderPage()}</div>
      </div>
    </div>
  );
};

export default App;


