import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { api } from '../config/constant.js';
import DataTable,{createTheme} from 'react-data-table-component';
import moment from 'moment';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
const limit = 50;




createTheme('solarized', {
  divider: {
    default: '#0d6efd',
  },
});

export default function Driver({localS}) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDGXDZajbxnXvvbC7TobxGQHzjfUPPMuMs',
  });
  console.log('rappport leve bac ',localS)
  // pdfMake.vfs = pdfFonts.pdfMake.vfs;
   const generatePdf = async ( listAnomalies_bac, type ) => {
    // Fonction pour construire les données du tableau
    const buildTableBody = (data, columns) => {
      const body = [];

      // Ajouter les en-têtes de colonne
      body.push(columns);

      // Ajouter les lignes de données
      data.forEach((element) => {
        const dataRow = [];
        columns.forEach((column) => {
          if (column === "Entreprise") {
            const entreprise =
              element.entrepriseId != null ? element.entreprise.name : "";
            dataRow.push(entreprise);
          }
           if (column === "Heure / Date") {
            const formattedDate = moment(element.createdAt).format(
              "YYYY-MM-DD HH:mm"
            );
            dataRow.push(formattedDate);
          }
          if (column === "Bom") {
            const entreprise =
              element.bom != null ? element.bom : "";
            dataRow.push(entreprise);
          }
           if (column === "Conducteur/N° Circuit") {
            const entreprise =
              element.conducteurcircuit != null ? element.conducteurcircuit : "";
            dataRow.push(entreprise);
          }

          if (column === "Numéro de Bac") {
            const chauffeur =
              element.numerobac != null
                ? element.numerobac
                : "";
            dataRow.push(chauffeur);
          }
          if (column === "Lieu de Bac collecté") {
            const circuit =
              element.lieubac != null ? element.lieubac : "";
            dataRow.push(circuit);
          }
          if (column === "Coordonées GPS Bac") {
            const latLong =
              element.gps != null
                ? {
                    text: `${element.gps}`,
                    link: `https://www.google.com/maps?q=${element.gps}`,
                  }
                : "";
            dataRow.push(latLong);
          }
        });
        body.push(dataRow);
      });

      return body;
    };

    // Fonction pour créer une table
    const table = (data, columns) => ({
      table: {
        headerRows: 1,
        body: buildTableBody(data, columns),
      },
    });

    // Définir la structure du PDF
    const dd = {
      content: [
        { text: "Rapport levée bac", style: "header" },
        table(listAnomalies_bac, [
          "Entreprise",
          "Heure / Date",
          "Bom",
          "Conducteur/N° Circuit",
          "Numéro de Bac",
          "Lieu de Bac collecté",
          "Coordonées GPS Bac"
        ]),
      ],
    };

    // Générer le PDF en fonction du type (affiche, download, print)
    if (type === "affiche") {
      pdfMake.createPdf(dd).open();
    } else if (type === "download") {
      pdfMake.createPdf(dd).download();
    } else if (type === "print") {
      pdfMake.createPdf(dd).print();
    }
  };

   const [bomforfiltre, setbomforfiltre] = useState('');
   const [listbom, setlistbom] = useState([]);

  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [entreprises, setEntreprises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentDriver, setCurrentDriver] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState({isOpen:false, lat:'', lng:''});
  const [actionType, setActionType] = useState("");
  const [searchText, setSearchText] = useState("");
  const transformImageUrl = (url) => `data:image/png;base64,${url}`;
  const mapElementRef = useRef(null);
    const openCartRef = useRef(null);
 const [selectedDates, setSelectedDates] = useState({ debut: '', Hdebut:'', Hfin:'', fin: '' });
  const [entrepriseType, setentrepriseType] = useState(localS[2]);

  //const entrepriseType = localStorage.getItem("entrepriseType");

  useEffect(() => {
    fetchEntreprises();
    loadDrivers();

  }, []);

  const loadDrivers = async () => {
    setIsLoading(true);
    try {


      const response = entrepriseType === "1"
        ? await axios.get(api +`/rapportleveebac/getAll/`+limit)
        : await axios.post(api + `/rapportleveebac/getAllByEntreprise/` + limit, { entrepriseId: localS[0]});

      const anomalies = response.data.data.map((item, index) => ({
        ...item,
        image_: transformImageUrl(item.urlPhoto),
        michely: `${index + 1}`,
      }));
      setbomforfiltre( response.data.data[response.data.data.length - 1].bom)
      setDrivers(anomalies);
      setFilteredDrivers(anomalies);
      const response0 = entrepriseType === "1"
        ? await axios.get(api +`/rapportleveebac/getAllBoms`)
        : await axios.post(api + `/rapportleveebac/getBomsByEntreprise`, { entrepriseId: localS[0] });
         const tatay = response0.data.data .map((item, index) => ({
        ...item,
        michely: `${index + 1}`,
      }))
        setlistbom(tatay)

    } catch (error) {
      console.error("Erreur lors du chargement des anomalies :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEntreprises = () => {
    axios.get(api + "/entreprises/getAll")
      .then(response => setEntreprises(response.data.data));
  };
const handleSearch = (e) => {
  const searchValue = e.target.value;
  setSearchText(searchValue);
  const filteredData = drivers.filter((driver) => {
    const a0 = driver?.entrepriseId ? driver.entreprise.name.toLowerCase() : "";
    const a1 = driver.conducteurcircuit ? driver.conducteurcircuit.toLowerCase() : "";
    const a2 = driver?.numerobac ? driver?.numerobac.toLowerCase() : "";
    const a3 = driver?.lieubac ? driver?.lieubac.toLowerCase() : "";
    const a4 = driver?.gps ? driver?.gps.toLowerCase() : "";

    return (
      a0.includes(searchValue.toLowerCase()) ||
      a1.includes(searchValue.toLowerCase()) ||
      a2.includes(searchValue.toLowerCase()) ||
      a3.includes(searchValue.toLowerCase()) ||
      a4.includes(searchValue.toLowerCase())
    );
  });
  setFilteredDrivers(filteredData);
};



  const handleAddOrUpdateDriver = () => {
   /* if (
      !currentDriver.firstname ||
      !currentDriver.lastname ||
      !currentDriver.immatricule ||
      !currentDriver.entrepriseId
    ) {
      alert("Veuillez remplir tous les champs.");
      return;
    }
    setIsLoading(true);
    const url = actionType === "add"
      ? api + "/drivers/add"
      : api + "/drivers/update";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(currentDriver)
    })
    .then(response => response.json())
    .then(() => {
      loadDrivers();
      resetForm();
    })
    .catch(error => {
      console.error("Error saving driver:", error);
      setIsLoading(false);
    });*/
  };

const handleDeleteDriver = async (id) => {
      const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ?");
  if (confirmed) {
    setIsLoading(true);
    try {
       await axios.post(api + `/rapportleveebac/delete`, { id });
      loadDrivers();
    } catch (error) {
      console.error("Erreur lors de la suppression :", error);
    } finally {
      setIsLoading(false);
    }}
};

  const deleteMultipleAnomalies = async () => {
   const selectedData = filteredDrivers.filter((item) => selectedItems.includes(item.id));
   const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ?");
  if (confirmed) {
    setIsLoading(true);
    try {
      await axios.post(api +`/rapportleveebac/supprimerPlusieurs`, { arrayrapportleveebac: selectedItems });
      loadDrivers();
    } catch (error) {
      console.error("Erreur lors de la suppression multiple :", error);
    } finally {
      setIsLoading(false);
      setSelectedItems([]);
      loadDrivers()
    }}
  };

  const resetForm = () => {
   setShowModal1({isOpen:false });
   // setCurrentDriver({});
   // setIsLoading(false);
  };

  const openModal = (driver = {}) => {
    setActionType(driver.id ? "update" : "add");
    setCurrentDriver(driver);
    setShowModal(true);
  };

  const oa = [
    {
    name: "",
    cell: (row) => (
     <input
          type="checkbox"
          checked={selectedItems.includes(row.id)}
          onChange={(e) => handleCheckboxChange(row.id, e.target.checked)}
     />
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
   // width: "50px",
  },
   {
    name: "",
    selector: (row) => row.michely, // Add 1 to make it 1-based
   width: "60px",
  },
  // { name: "ID",
  //   minWidth: '90px',
  //   maxWidth: '90px',
  //   selector: row => row.id, sortable: true },
  {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Heure / Date</div>,
    selector: (row) => moment(row.createdAt).format('yyyy-MM-DD HH:mm:ss'),
    sortable: true,
    minWidth: '160px',
    maxWidth: '170px',
    wrap:true
  },
  {
    name: "Véhicule",
    selector: (row) => row?.bom != null ? row?.bom :'',
    sortable: true,
  /*  minWidth: '100px',
    maxWidth: '150px',*/
    wrap:true
  },
  {
    name: <div style={{ maxWidth: '160px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Conducteur/N° Circuit</div>,
    selector: (row) => row?.conducteurcircuit != null ? row?.conducteurcircuit :'',
    sortable: true,

    wrap:true
  },
  {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Numéro de Bac</div>,
    selector: (row) => row?.numerobac != null ? row?.numerobac :'',
    sortable: true,
    minWidth: '100px',
    maxWidth: '150px',
    wrap:true
  },
  {
    name: <div style={{ maxWidth: '170px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Lieu de Bac collecté</div>,
    selector: (row) => row?.lieubac != null ? row?.lieubac :'',
    sortable: true,
    /*minWidth: '100px',
    maxWidth: '150px',*/
    wrap:true
  },
  {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Coordonées GPS Bac</div>,
    selector: (row) => row?.idBac != null ? row.idBac :'',
    sortable: true,
    cell: (row) =>  <a href="#" onClick={() => openMap(row.gps)}>
                    {row.gps}
                  </a>
  },
    {
      name: "Actions",
      cell: row => (
        <>
          <Button onClick={() => handleDeleteDriver(row.id)} variant="danger" size="sm" className="ms-2"><i className="fa fa-trash"></i></Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const copiedColumns = [...oa]; // Copie de `oa`
  if(entrepriseType === '1')
  copiedColumns.splice(2, 0, {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Entreprise</div>,
    selector: row => row?.entreprise?.name,
    sortable: true,
    wrap: true,
  });

  const columns = entrepriseType === "1" ? copiedColumns : oa;


 const paginationOptions = {
    rowsPerPageText: "Lignes par page", // Texte traduit
    rangeSeparatorText: "de", // Texte entre les numéros
    selectAllRowsItem: false, // Option pour "Sélectionner tout" (désactivé ici)
    selectAllRowsItemText: "Tous", // Texte si activé
  };


/*
   const openMap = (latitude, longitude) => {
    const mapProperties = {
      center: { lat: latitude, lng: longitude },
      zoom: 15,
      mapTypeId: 'roadmap'
    };
    new google.maps.Map(mapElementRef.current, mapProperties);
    new google.maps.Marker({
      position: { lat: latitude, lng: longitude },
      map: mapElementRef.current
    });
  };
  */
   const openMap = (latitude, longitude) => {
    setShowModal1({isOpen:true, lat:parseFloat(latitude), lng: parseFloat(longitude)});
  };

 const [selectedItems, setSelectedItems] = useState([]);
  const handleCheckboxChange = (id, isChecked) => {
    setSelectedItems((prev) =>
      isChecked
        ? [...prev, id] // Ajouter l'ID si la case est cochée
        : prev.filter((itemId) => itemId !== id) // Supprimer l'ID si la case est décochée
    );
  };

const handleGenerateExcel = () => {
  let id_E = entrepriseType === "1" ? "" : localS[0] || "";
  let listRapportBacExcel = [];

  if (filteredDrivers.length > 0) {
    setIsLoading(true);

    for (let i = 0; i < filteredDrivers.length; i++) {
      const item = filteredDrivers[i];
      if (item.createdAt !== null && item.createdAt !== undefined) {
        item.createdAt = moment(item.createdAt).format("YYYY-MM-DD HH:mm:ss");
      }
      listRapportBacExcel.push(item);
    }

    let body = {
      listRapportBacExcel,
      id_E: id_E,
    };
    fetch(api + '/rapportleveebac/excel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 200 && data.url) {
          const anchor = document.createElement('a');
          anchor.href = data.url; // URL fournie par le backend
         // anchor.download = 'anomalies.xlsx'; // Optionnel, pour forcer un nom de fichier
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        } else {
          console.error("Erreur : L'URL du fichier Excel est invalide.");
        }
      })
      .catch(error => console.error("Erreur lors de la génération du fichier Excel :", error))
      .finally(() => setIsLoading(false));
  }
};

   const handleDateChange = (e) => {
    setSelectedDates({ ...selectedDates, [e.target.name]: e.target.value });
  };

  function formatage_date(imput_date){
    let ladate = imput_date;
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1).toString().padStart(2,'0')+'-'+ladate.getDate().toString().padStart(2,'0')
  }

   function less_day_date(date, nbr){
    let dateStart = new Date(date);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() - nbr));
    return formatage_date(dateEnd);
  }

  function add_day_date(date, nbr){
    let dateStart = new Date(date);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() + nbr));
    return formatage_date(dateEnd);
  }
    function date_aujour_d_hui(){
    let ladate = new Date();
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1).toString().padStart(2,'0')+'-'+ladate.getDate().toString().padStart(2,'0')
  }
  function traitement_semaine(aujourdhui){
    let ladate = new Date();
    let position = ladate.getDay();
    let debut_semaine =  less_day_date(aujourdhui, position);
    let fin_semaine = add_day_date(debut_semaine,6);
    return {debut_semaine, fin_semaine}
  }

  function traitement_mois(aujourdui){
    let mois = aujourdui.slice(5,7);
    let annees = aujourdui.slice(0,4);
    let nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
    let debut_mois = aujourdui.slice(0,8)+'01';
    let fin_mois = aujourdui.slice(0,8)+(nbrjrsmoi.getUTCDate());
    return {debut_mois, fin_mois};
  }
 function date_lendemain(){
    const today = new Date();
    const tomorrow = new Date();
    return tomorrow.setDate(today.getDate()-1);
  }
  useEffect(() => {

    const blob = async () => {
    const aujourdhui = date_aujour_d_hui();

    let dateStart = new Date(aujourdhui);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() - 1));
    const hier = formatage_date(dateEnd);

    const { debut_semaine, fin_semaine } = traitement_semaine(aujourdhui);
    const { debut_mois, fin_mois } = traitement_mois(aujourdhui);
    let basta= {...selectedDates, aujourdhui, hier, debut_semaine, fin_semaine, debut_mois, fin_mois };
            console.log('type ssss', localS)

      if(localS[2] !== '1' && localS[0] === "12"){
        basta = {...basta, Hdebut : '00:00', Hfin : '23:59', debut: aujourdhui, fin : aujourdhui}
      }else if(localS[2] !== '1' && localS[0] === "10"){
        let debut= moment.utc(date_lendemain()).format("yyyy-MM-DD");
       basta = {...basta, Hdebut : '17:00', Hfin : '16:00', debut, fin : aujourdhui}
    }else{
      basta = {...basta, Hdebut : '01:00', Hfin : '23:59', debut: aujourdhui, fin : aujourdhui};
    }
    setSelectedDates(basta);

    //



    }
    blob()
  }, []);





  async function handleFilterByDate(start, end){
    let start_ = start;
    let end_ = end;
    if(selectedDates.Hdebut !== '' || selectedDates.Hfin !== ''){
      start_ = start + " " + selectedDates.Hdebut + ":00";
      end_ = end + " " + selectedDates.Hfin + ":00";
    }
    //setdebutbybom ( start_);
    //setfinbybom ( end_);

    console.log('start_', start_, 'end_', end_)
    console.log('selectedDates ', selectedDates)
    setIsLoading(true);
    setTimeout(()=>setIsLoading(false),10000);
    const response = entrepriseType === "1"
        ? await axios.post(api + `/rapportleveebac/betweenTwoDates`, {
          startDate: start_,
          endDate: end_,
          immatriculation:bomforfiltre

        })
        : await axios.post(api + `/rapportleveebac/betweenTwoDatesByEntreprise`, {
          entrepriseId: localS[0],
          startDate: start_,
          endDate: end_,
          immatriculation:bomforfiltre

        });

    setDrivers(response.data.data);
    setFilteredDrivers(response.data.data);
  }



   async function choisirbomfilter(immatriculation) {
    setbomforfiltre(immatriculation);
    let id_E = "";
    if(entrepriseType == "1"){
    }else{
      id_E = localS[0]
    }
    setIsLoading(true);
    setTimeout(()=>setIsLoading(false),10000);

      let start_ = selectedDates.debut + " " + selectedDates.Hdebut + ":00";
      let end_ = selectedDates.fin + " " + selectedDates.Hfin + ":00";
    setIsLoading(true);
    setTimeout(()=>setIsLoading(false),10000);
    console.log('startend voaray am rapport bom', start_, 'end', end_, 'this.debut',selectedDates.debut, 'this.fin',selectedDates.fin)

      if(entrepriseType == "1"){
        let body = {
          startDate: start_,
          endDate: end_,
          immatriculation: immatriculation,
        };

        const res = await axios.post(api + `/rapportleveebac/getRapportBacbybom/`+limit, body)
              if(res.data.data){
                setIsLoading(false)
                setDrivers(res?.data?.data)
                setFilteredDrivers(res?.data?.data)
              }
      }else{
        let bodyE = {
          entrepriseId: localS[0],
          startDate: start_,
          endDate: end_,
          immatriculation: immatriculation,
        };
        if(bodyE.entrepriseId=="3"){
          let bodyE = {
            entrepriseId: localS[0],
            startDate: moment(start_).format("YYYY-MM-DD HH:mm:ss"),
            endDate: moment(end_).format("YYYY-MM-DD HH:mm:ss"),
            immatriculation: immatriculation
            };
              const res1 = await axios.post(api + `/rapportleveebac/getRapportBacbyentreprisebybom/`+limit, bodyE)
              if(res1.data.data){
                setIsLoading(false)
                setDrivers(res1.data.data)
                setFilteredDrivers(res1.data.data)
              }
        }else{
          const res2 = await axios.post(api + `/rapportleveebac/getRapportBacbyentreprisebybom/`+limit, bodyE)
              if(res2.data.data){
                setIsLoading(false)
                setDrivers(res2.data.data)
                setFilteredDrivers(res2.data.data)
              }

        };
      };
  }

 if (!isLoaded) {
    return <div>Chargement de la carte...</div>;
  }

  return (
    <div  style={{ maxHeight: window.innerHeight - 100, overflowY: 'auto' }} className="driver-page">

        <div className="btn-periode" style={{ marginTop: 15 }} role="group" aria-label="Basic example">
        <button style={{marginRight:'5px'}} className="btn btn-sm btn-info" onClick={() => handleFilterByDate(selectedDates.aujourdhui, selectedDates.aujourdhui)}>
          Aujourd'hui
        </button>
        <button style={{marginRight:'5px'}}  className="btn btn-sm btn-info" onClick={() => handleFilterByDate(selectedDates.hier, selectedDates.hier)}>
          Hier
        </button>
        <button style={{marginRight:'5px'}}  className="btn btn-sm btn-info" onClick={() => handleFilterByDate(selectedDates.debut_semaine, selectedDates.fin_semaine)}>
          Semaine
        </button>
        <button style={{marginRight:'5px'}}  className="btn btn-sm btn-info" onClick={() => handleFilterByDate(selectedDates.debut_mois, selectedDates.fin_mois)}>
          M
        </button>
        {/*<button style={{marginRight:'5px'}}  className="btn btn-sm btn-info" onClick={() => generatePdf('print')}>Imprimer</button> */}
        <button style={{marginRight:'5px'}}  className="btn btn-sm btn-info" onClick={() => handleFilterByDate(selectedDates.debut, selectedDates.fin)}>
          Afficher
        </button>
        <select  style={{marginRight:'5px'}}
            value={""}
            onChange={(e) =>{
              const val = e.target.value;
              if(val === 'excel')
                handleGenerateExcel()
                else
                  generatePdf(filteredDrivers, "download" )
            }}
            className="btn btn-info btn-sm dropdown-toggle"
          >
            <option value="">Telecharger</option>
              <option className="dropdown-item" value="excel">Fichier Excel</option>
              <option className="dropdown-item" value="pdf">Fichier Pdf</option>
          </select>
          <select  style={{marginRight:'5px'}}
            value={""}
            onChange={(e) =>{
              const val = e.target.value;
              choisirbomfilter(val)
            }}
            className="btn btn-info btn-sm dropdown-toggle"
          >
            <option value="">{bomforfiltre == ''?'Bom': bomforfiltre}</option>{
              listbom.map(item=>{
                  return <option key={'-'+item.id} className="dropdown-item" value={item.immatriculation}>{item.immatriculation}</option>

              })
            }
          </select>
      {/*  <button  className="btn btn-info btn-sm dropdown-toggle" type="button" id="dropdownMenuButton">
          Télécharger
        </button>
        <div className="dropdown-menu">
          <a className="dropdown-item" onClick={handleGenerateExcel}>Fichier Excel</a>
          <a className="dropdown-item" onClick={handleGeneratePdf}>Fichier Pdf</a>
        </div> */}
        <input id="date" type="date" name="debut"
        value={selectedDates.debut}
        onChange={handleDateChange} />
        <input id="time" type="time"
        value={selectedDates.Hdebut}
        name="Hdebut" onChange={handleDateChange} />
        <input id="date1" type="date" name="fin"
        value={selectedDates.fin}
        onChange={handleDateChange} />
        <input id="time1" type="time"
        value={selectedDates.Hfin}
        name="Hfin" onChange={handleDateChange} />
      </div>

      <input
        type="text"
        placeholder="Rechercher..."
        value={searchText}
        onChange={handleSearch}
        className="form-control mb-3 border-primary"
        style={{marginTop:'10px'}}
      />

      <div className="data-table">
        {isLoading ? (
          <ClipLoader color={"#123abc"} loading={isLoading} size={50} />
        ) : (
          <DataTable
            columns={columns}
            data={filteredDrivers}  // Utilisation des données filtrées
            pagination
                         paginationComponentOptions={paginationOptions}
                           paginationPerPage={30} // Réglage par défaut à 30
            paginationRowsPerPageOptions={[10, 30, 50]}
 theme="solarized"
            fixedHeader
            fixedHeaderScrollHeight={`${(window.innerHeight/2) +(window.innerHeight/5.5)}px`}

            //style={{width: "100%"}}
          />
        )}
      </div>

      <Modal show={showModal1.isOpen} onHide={resetForm}>
        <Modal.Body>
          <GoogleMap
              //ref={mapElementRef}
              mapContainerStyle={{
                width: '100%',
                height: '400px',
              }}
              center={{
                lat: showModal1.lat, // Coordonnées de Paris (exemple)
                lng: showModal1.lng,
              }}
              zoom={12}
            >
              <Marker position={{ lat: showModal1.lat, lng: showModal1.lng }} />
            </GoogleMap>
        </Modal.Body>
      </Modal>
      <button className="btn btn-sm btn-danger" onClick={deleteMultipleAnomalies}>
          Supprimer les rapports cochés
        </button>

    </div>
  );
}
