import React from "react";

const Sidebar = ({ toPage, isOpen, navigate }) => (

  <aside
   style={{ minHeight: window.innerHeight - 100, overflowY: 'auto', minWidth:isOpen?`${200}px`:`${50}px` }}

  className={`sidebar bg-dark text-light ${isOpen ? "open" : "closed"}`}>
    <div  onClick={() => toPage("Accueil")} className="brand p-3 d-flex align-items-center">
      <img src="/poubelle.jpg" alt="Logo" style={{ width: 30 }} />
      {isOpen && <span  style={{marginLeft:'10px'}} >App Collect</span>}
    </div>
    <nav className="mt-4">
      <ul className="nav flex-column">
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("chauffeurs")}>
            <i className="fa fa-user-circle"></i> {isOpen && "Chauffeurs"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("ripeurs")}>
            <i className="fa fa-user-circle"></i> {isOpen && "Ripeurs"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("circuits")}>
            <i className="fa fa-road"></i> {isOpen && "Circuits"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("vehicules")}>
            <i className="fa fa-car"></i> {isOpen && "Véhicules"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("anomalies")}>
            <i className="fa fa-bolt"></i> {isOpen && "Liste Anomalies"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapports anomalies")}>
            <i className="fa fa-bolt"></i> {isOpen && "Rapports anomalies"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapports journaliers")}>
            <i className="fa fa-list"></i> {isOpen && "Rapports journaliers"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapports pointages")}>
            <i className="fa fa-list"></i> {isOpen && "Rapports pointages"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapports collects")}>
            <i className="fa fa-list"></i> {isOpen && "Rapports Collects"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapports d'affectations")}>
            <i className="fa fa-list"></i> {isOpen && "Rapports d'affectations"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Tonnages")}>
            <i className="fa fa-list"></i> {isOpen && "Tonnages"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Vidages")}>
            <i className="fa fa-list"></i> {isOpen && "Vidages"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Rapports levée bacs")}>
            <i className="fa fa-list"></i> {isOpen && "Rapports levée bacs"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("entreprises")}>
            <i className="fa fa-user-circle"></i> {isOpen && "Entreprises"}
          </a>
        </li>
        <li className="nav-item">
          <a href="#" className="nav-link text-light" onClick={() => toPage("Config rapport d'affectation")}>
            <i className="fa fa-edit"></i> {isOpen && "Config rapport d'affectation"}
          </a>
        </li>
      </ul>
    </nav>
  </aside>
);

export default Sidebar;
