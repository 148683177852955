import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { api } from '../config/constant.js';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
const limit = 50;



const headerStyle = {
  backgroundColor: '#4CAF50',
  color: 'white',
  padding: '8px',
};

const cellStyle = {
  border: '1px solid #ddd',
  padding: '8px',
};

const rowStyleEven = {
  backgroundColor: '#f9f9f9',
};

const rowStyleOdd = {
  backgroundColor: '#ffffff',
};

export default function Driver({localS}) {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyDGXDZajbxnXvvbC7TobxGQHzjfUPPMuMs',
  });

  function dateDiffbycreatedAt(date1, date2){
    if(date1 == undefined || date1 == null)
      return '----';
    if (date2 == undefined || date2 == null)
      return '----';

    let d1 = new Date(date1);
    let d2 = new Date(date2);

    // @ts-ignore
    let difference = Math.abs(d1 - d2); //to get absolute value
    //calculate for each one
    let Days = Math.floor(difference / ( 1000 * 60 * 60 * 24 ));
    let Hours = Math.floor((difference / ( 1000 * 60 * 60 )) % 24);
    let Mins = Math.floor((difference / ( 1000 * 60 )) % 60);
    let Seconds = Math.floor((difference / ( 1000 )) % 60);
    return `${Hours.toString().padStart(2, '0')}:${Mins.toString().padStart(2, '0')}:${Seconds.toString().padStart(2, '0')}`;
  }

  // pdfMake.vfs = pdfFonts.pdfMake.vfs;
   const generatePdf = async ( listAnomalies_bac, type ) => {
    // Fonction pour construire les données du tableau
    const buildTableBody = (data, columns) => {
      const body = [];

      // Ajouter les en-têtes de colonne
      body.push(columns);

      // Ajouter les lignes de données
      data.forEach((element) => {
        const dataRow = [];
        columns.forEach((column) => {
          if (column === "Entreprise") {
            const entreprise =
              element.entrepriseId != null ? element.entreprise?.name : "";
            dataRow.push(entreprise);
          }
          if (column === "Date") {
            const formattedDate = moment(element.createdAt).format(
              "YYYY-MM-DD HH:mm"
            );
            dataRow.push(formattedDate);
          }
          if (column === "Circuit") {
            const bom = element?.collect?.circuitdebut != null ? element?.collect?.circuitdebut:'';
            dataRow.push(bom);
          }
          if (column === "Bom") {
            const bom = element.bomId != null ? element?.bom?.immatriculation :'----';
            dataRow.push(bom);
          }
        if (column === "Chauffeur") {
            const firstnamelastname =
              element?.driver?.firstname != null
                ? element?.driver?.firstname + " " + element?.driver?.lastname
                : "";
            dataRow.push(firstnamelastname);
          }
          if (column === "Ripeur 1") {
            const firstnamelastname =
              element?.collect?.lastnameripeur1 != null
                ? element?.collect?.firstnameripeur1 + " " + element?.collect?.lastnameripeur1
                : "";
            dataRow.push(firstnamelastname);
          }
          if (column === "Ripeur 2") {
            const firstnamelastname =
              element?.collect?.lastnameripeur2 != null
                ? element?.collect?.firstnameripeur2 + " " + element?.collect?.lastnameripeur2
                : "";
            dataRow.push(firstnamelastname);
          }
          if (column === "Ripeur 1") {
            const firstnamelastname =
              element?.collect?.lastnameripeur3 != null
                ? element?.collect?.firstnameripeur3 + " " + element?.collect?.lastnameripeur3
                : "";
            dataRow.push(firstnamelastname);
          }
          if (column === "H de départ") {
            const formattedDate = element?.collect?.createdAtBegin != null ?  moment(element?.collect?.createdAtBegin).format(
              "HH:mm:ss"
            ) : '----';
            dataRow.push(formattedDate);
          }


        });
        body.push(dataRow);
      });

      return body;
    };

    // Fonction pour créer une table
    const table = (data, columns) => ({
      table: {
        headerRows: 1,
        body: buildTableBody(data, columns),
      },
    });

    // Définir la structure du PDF
    const dd = {
      content: [
        { text: "Rapport pointage", style: "header" },
        table(listAnomalies_bac, [
          "Entreprise",
          "Date",
          "Circuit",
          "Bom",
          "Chauffeur",
          "Ripeur 1",
          "Ripeur 2",
          "Ripeur 3",
          "Heure de départ",
        ]),
      ],
    };

    // Générer le PDF en fonction du type (affiche, download, print)
    if (type === "affiche") {
      pdfMake.createPdf(dd).open();
    } else if (type === "download") {
      pdfMake.createPdf(dd).download();
    } else if (type === "print") {
      pdfMake.createPdf(dd).print();
    }
  };


  const [drivers, setDrivers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [entreprise, setEntreprise] = useState(null);
    const [entreprises, setEntreprises] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [currentEmail, setCurrentEmail] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showModal1, setShowModal1] = useState({isOpen:false, lat:'', lng:''});
  const [actionType, setActionType] = useState("");
  const [searchText, setSearchText] = useState("");
  const transformImageUrl = (url) => `data:image/png;base64,${url}`;
  const mapElementRef = useRef(null);
    const openCartRef = useRef(null);
 const [selectedDates, setSelectedDates] = useState({ debut: '', Hdebut:'', Hfin:'', fin: '' });

  //const entrepriseType = localStorage.getItem("entrepriseType");
  const [entrepriseType, setentrepriseType] = useState(localS[2]);
    const [objetEmail, setObjetEmail] = useState("");
    const [urlImage, setUrlImage] = useState("");
  const [image, setImage] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

/*
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [uploadStatus, setUploadStatus] = useState("");
    const fileInputRef = useRef(null);

    const [file, setFile] = useState(null);
    const [fileInputLabel, setFileInputLabel] = useState("");
    const [logo, setLogo] = useState("");
*/




  useEffect(() => {
    fetchEntreprise();
    fetchEntreprises();
    loadDrivers();
    loadEmail()
  }, []);

    const fetchEntreprises = () => {
    axios.get(api + "/entreprises/getAll")
      .then(response => setEntreprises(response.data.data));
  };
  const loadDrivers = async () => {
   // setIsLoading(true);
    try {
      const response = entrepriseType === "1"
        ? await axios.get(api +`/collects/getAll/`+limit)
        : await axios.post(api + `/collects/getAllByEntreprise/` + limit, { entrepriseId: localS[0] });

      setDrivers(response.data.data);
      setFilteredDrivers(response.data.data);
    } catch (error) {
      console.error("Erreur lors du chargement des anomalies :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadEmail = async () => {
    setIsLoading(true);
    try {
      const response = entrepriseType === "1"
        ? await axios.get(api +`/email/getAll/`+limit)
        : await axios.post(api + `/email/getAllByEntreprise/` + limit, { entrepriseId: localS[0] });
        if(response)
          setIsLoading(false)
      setEmails(response.data.data);
    } catch (error) {
      setIsLoading(false)
      console.error("Erreur lors du chargement des anomalies :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchEntreprise = () => {
    axios.post(api + "/getentreprise",  { entrepriseId: localS[0] })
      .then(response =>{
        if(response.data?.data?.logo)
         setUrlImage(`${api}/${response.data.data.logo}`);
              if(response.data?.data?.objetEmail)
        setObjetEmail(response?.data?.data?.objetEmail);
        setEntreprise(response.data.data)});

  };




const handleSearch = (e) => {
  const searchValue = e.target.value;
  setSearchText(searchValue);
  const filteredData = drivers.filter((driver) => {
    const a0 = driver?.entrepriseId ? driver.entreprise?.name.toLowerCase() : "";
    const a1 = driver?.createdAt ? driver?.createdAt.toLowerCase() : "";

    const a2 = driver?.collect?.circuitdebut ? driver?.collect?.circuitdebut.toLowerCase() : "";

    const a3 = driver?.collect?.bomId ? driver?.collect?.bom?.immatriculation.toLowerCase() : "";
    const a4 = driver?.collect?.driver?.firstname ?  driver?.collect?.driver?.firstname.toLowerCase() : "";
    const a5 =  driver?.collect?.driver?.lastname ?  driver?.collect?.driver?.lastname.toLowerCase() : "";

    const a6 = driver?.collect?.firstnameripeur1 ?  driver?.collect?.firstnameripeur1.toLowerCase() : "";
    const a7 =  driver?.collect?.lastnameripeur1 ?  driver?.collect?.lastnameripeur1.toLowerCase() : "";

    const a8 = driver?.collect?.firstnameripeur2 ?  driver?.collect?.firstnameripeur2.toLowerCase() : "";
    const a9 =  driver?.collect?.lastnameripeur2 ?  driver?.collect?.lastnameripeur2.toLowerCase() : "";

    const a10 = driver?.collect?.firstnameripeur3 ?  driver?.collect?.firstnameripeur3.toLowerCase() : "";
    const a11 =  driver?.collect?.lastnameripeur3 ?  driver?.collect?.lastnameripeur3.toLowerCase() : "";

//date depart reste ici

    return (
      a0.includes(searchValue.toLowerCase()) ||
      a1.includes(searchValue.toLowerCase()) ||
      a2.includes(searchValue.toLowerCase()) ||
      a3.includes(searchValue.toLowerCase()) ||
      a4.includes(searchValue.toLowerCase()) ||
      a5.includes(searchValue.toLowerCase()) ||
      a6.includes(searchValue.toLowerCase()) ||
      a7.includes(searchValue.toLowerCase()) ||
      a8.includes(searchValue.toLowerCase()) ||
      a9.includes(searchValue.toLowerCase()) ||
      a10.includes(searchValue.toLowerCase()) ||
      a11.includes(searchValue.toLowerCase())
    );
  });
  setFilteredDrivers(filteredData);
};



function validerEmail(email) {
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
}
  const handleAddOrUpdateEmail = () => {
    if (
      !currentEmail.email,
      !currentEmail.HeureEnvoi
     // !currentEmail.entrepriseId
    ) {
      alert("Veuillez remplir tous les champs.");
      return;
    }
      if(!validerEmail(currentEmail.email)){
            alert("Veuillez ajouter un email valide.");
        return;
    }
     if(!currentEmail.entrepriseId){
        currentEmail.entrepriseId = localS[0]
    }
    setIsLoading(true);
    const url = actionType === "add"
      ? api + "/email/add"
      : api + "/email/update";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(currentEmail)
    })
    .then(response => response.json())
    .then(() => {
      loadEmail();
      resetEmail();
      setIsLoading(false)
    })
    .catch(error => {
      console.error("Error saving driver:", error);
      setIsLoading(false);
    });
  };

const handleDeleteDriver = async (id) => {
     /* const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ?");
  if (confirmed) {
      setIsLoading(true);
      setDrivers([]);
      setFilteredDrivers([]);
    try {
       await axios.post(api + `/collects/delete`, { id });
      loadDrivers();
    } catch (error) {
      console.error("Erreur lors de la suppression :", error);
    } finally {
      setIsLoading(false);
    }}*/
};


const handleDeleteEmail = async (id) => {
      const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ?");
  if (confirmed) {
      setIsLoading(true);
      setEmails([]);
    try {
       await axios.post(api + `/email/delete`, { id });
      loadEmail();
    } catch (error) {
      console.error("Erreur lors de la suppression :", error);
    } finally {
      setIsLoading(false);
    }}
};

  const deleteMultipleAnomalies = async () => {
  /* const selectedData = filteredDrivers.filter((item) => selectedItems.includes(item.id));
   const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ?");

  if (confirmed) {
      setIsLoading(true);
      setDrivers([]);
      setFilteredDrivers([]);
    try {
      await axios.post(api +`/collects/supprimerPlusieurs`, { arrayPointages: selectedItems });
      loadDrivers();
    } catch (error) {
      console.error("Erreur lors de la suppression multiple :", error);
    } finally {
      setIsLoading(false);
      setSelectedItems([]);
      loadDrivers()
    }}*/
  };

  const resetForm = () => {
   setShowModal1({isOpen:false });
   // setIsLoading(false);
  };

  const resetEmail = () => {
   setShowModal(false);
   setCurrentEmail({});

  };


  //cronLocal
  const envoyerEmailManually = async () => {
      //  setIsLoading(true);
        setTimeout(() => setIsLoading(false), 10000);
        let id_E = "";
        if (entrepriseType == "1") {
            id_E = localS[0]
        } else {
            id_E = localStorage.getItem('entrepriseId')
        }
        let listAffectationEmail = [];

        for (let i = 0; i < drivers.length; i++) {
            const item = drivers[i];
            if (item.collect) {
                let transform0 = null;
                try {
                } catch (e) {
                }
                if (item.collect.createdAtBegin !== null && item.collect.createdAtBegin !== undefined) {
                    transform0 = (item.collect.createdAtBegin).toString().substring(0, 10);

                     let tttt = moment(item.collect.createdAtBegin);
                    item.collect.createdAtBegin = tttt;
                }
            }
            listAffectationEmail.push(item);
        }
        let body = {
            listAffectation: listAffectationEmail,
            listEmail: emails,
            id_E: id_E
        };

      if((selectedOption && selectedOption === "option2") || selectedOption === null){
          body.cronLocal = true;
        }

        const resp = await axios.post(`${api}/email/envoyerEmailManually`, body);
        if(resp){
          loadDrivers()
          setIsLoading(false)
        }

  }


    const   handleSendEmailParUneSeulemnt = async (oaha) => {
      let id_E = "";
        if (entrepriseType == "1") {
            id_E = localS[0]
        } else {
            id_E = localStorage.getItem('entrepriseId')
        }
        let listAffectationEmail = [];
        let oneemail = [];
        oneemail.push(oaha.email)

        for (let i = 0; i < drivers.length; i++) {
            const item = drivers[i];
            if (item.collect) {
                let transform0 = null;
                try {
                } catch (e) {
                }
                if (item.collect.createdAtBegin !== null && item.collect.createdAtBegin !== undefined) {
                    transform0 = (item.collect.createdAtBegin).toString().substring(0, 10);

                     let tttt = moment(item.collect.createdAtBegin);
                    item.collect.createdAtBegin = tttt;
                }
            }
            listAffectationEmail.push(item);
        }
        let body = {
            listAffectation: listAffectationEmail,
            listEmail: oneemail,
            id_E: id_E
        };
      if((selectedOption && selectedOption === "option2") || selectedOption === null){
          body.cronLocal = true;
        }

        const resp = await axios.post(`${api}/email/envoyerEmailManually`, body);
        console.log(resp)
        if(resp){
          loadDrivers()
          alert('Email envoyé à '+ oaha.email)
        }
    }


        const   handleSendEmailUneSansRetardateur = async (oaha) => {
 let id_E = "";
        if (entrepriseType == "1") {
            id_E = localS[0]
        } else {
            id_E = localStorage.getItem('entrepriseId')
        }
        let listAffectationEmail = [];
        let oneemail = [];
        oneemail.push(oaha.email)

        for (let i = 0; i < drivers.length; i++) {
            const item = drivers[i];
            if (item.collect) {
                let transform0 = null;
                try {
                } catch (e) {
                }
                if (item.collect.createdAtBegin !== null && item.collect.createdAtBegin !== undefined) {
                    transform0 = (item.collect.createdAtBegin).toString().substring(0, 10);

                     let tttt = moment(item.collect.createdAtBegin);
                    item.collect.createdAtBegin = tttt;
                }
            }
            listAffectationEmail.push(item);
        }
        let body = {
            listAffectation: listAffectationEmail,
            listEmail: oneemail,
            id_E: id_E,
            noTime:true
        };
      if((selectedOption && selectedOption === "option2") || selectedOption === null){//esorina ts ilaina
          body.cronLocal = true;
        }

        const resp = await axios.post(`${api}/email/envoyerEmailManually`, body);
        console.log(resp)
        if(resp){
          loadDrivers()
          alert('Email envoyé à '+ oaha.email)
        }
    }


  const openModal = (email = {}) => {
    setActionType(email.id ? "update" : "add");
    setCurrentEmail(email);
    setShowModal(true);
  };

  const oa = [
   /* {
    name: "",
    cell: (row) => (
     <input
          type="checkbox"
          checked={selectedItems.includes(row.id)}
          onChange={(e) => handleCheckboxChange(row.id, e.target.checked)}
     />
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    button: true,
    width: "50px",
  },*/
  // { name: "ID",
  //   minWidth: '70px',
  //   maxWidth: '70px',
  //   selector: row => row.collect.id, sortable: true },
   {
    name: "",
    selector: (row, index) => index + 1, // Add 1 to make it 1-based
    width: "60px",
  },
  {
    name: "Date",
    selector: (row) => moment(row.createdAt).format('yyyy-MM-DD HH:mm:ss'),
    sortable: true,
    wrap:true,
    minWidth: '160px',
    maxWidth: '170px',

  },
   {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Circuit</div>,
    selector: (row) => row?.collect?.circuitdebut != null ? row?.collect?.circuitdebut :'',
    cell:(row) =>(<div  style={{
          position: 'relative',
          display: 'inline-block',
          cursor: 'pointer', // Indique que l'élément est survolable
        }}>{row?.collect?.circuitdebut != null ? row?.collect?.circuitdebut :''}</div>),
    sortable: true,
  },
  {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Véhicule</div>,
    selector: (row) => row?.collect?.bomId != null ? row?.collect?.bom?.immatriculation:'',
    sortable: true,
    minWidth: '100px',
    maxWidth: '150px',
    wrap:true
  },
  {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Chauffeur</div>,
    selector: (row) => row?.collect?.driver?.firstname != null ? row?.collect?.driver?.lastname+' '+row?.collect?.driver?.firstname:'',
    cell:(row) =>(<div  style={{
          position: 'relative',
          display: 'inline-block',
          cursor: 'pointer', // Indique que l'élément est survolable
        }}>{row?.collect?.driver?.firstname != null ? row?.collect?.driver?.lastname+' '+row?.collect?.driver?.firstname:''}</div>),
    sortable: true,
  },
   {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Ripeur 1</div>,
    selector: (row) => row?.collect?.lastnameripeur1 != null ? row?.collect?.lastnameripeur1+' '+row?.collect?.firstnameripeur1:'',
    cell:(row) =>(<div  style={{
          position: 'relative',
          display: 'inline-block',
          cursor: 'pointer', // Indique que l'élément est survolable
        }}>{row?.collect?.lastnameripeur1 != null ? row?.collect?.lastnameripeur1+' '+row?.collect?.firstnameripeur1:''}</div>),
    sortable: true,
  },
  {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Ripeur 2</div>,
    selector: (row) => row?.collect?.lastnameripeur2 != null ? row?.collect?.lastnameripeur2+' '+row?.collect?.firstnameripeur2:'',
    cell:(row) =>(<div  style={{
          position: 'relative',
          display: 'inline-block',
          cursor: 'pointer', // Indique que l'élément est survolable
        }}>{row?.collect?.lastnameripeur2 != null ? row?.collect?.lastnameripeur2+' '+row?.collect?.firstnameripeur2:''}</div>),
    sortable: true,
  },
  {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Ripeur 3</div>,
    selector: (row) => row?.collect?.lastnameripeur3 != null ? row?.collect?.lastnameripeur3+' '+row?.collect?.firstnameripeur3:'',
    cell:(row) =>(<div  style={{
          position: 'relative',
          display: 'inline-block',
          cursor: 'pointer', // Indique que l'élément est survolable
        }}>{row?.collect?.lastnameripeur3 != null ? row?.collect?.lastnameripeur3+' '+row?.collect?.firstnameripeur3:''}</div>),
    sortable: true,
  },
  {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>H de départ</div>,
    selector: (row) => row?.collect?.createdAtBegin ? moment(row?.collect?.createdAtBegin).format('HH:mm:ss') : '',
    sortable: true,
    //minWidth: '160px',
    maxWidth: '160px',

  }

    /*{
      name: "Actions",
      maxWidth: '100px',
      cell: row => (
        <>
          <Button onClick={() => handleDeleteDriver(row?.collect?.id)} variant="danger" size="sm" className="ms-2"><i className="fa fa-trash"></i></Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },*/
  ];

  const copiedColumns = [...oa]; // Copie de `oa`
  if(entrepriseType === '1')
  copiedColumns.splice(1, 0, {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Entreprise</div>,
    selector: row => row?.collect?.entreprise?.name,
    sortable: true,
    wrap: true,
  });

  const columns = entrepriseType === "1" ? copiedColumns : oa;


   const openMap = (latitude, longitude) => {
    setShowModal1({isOpen:true, lat:parseFloat(latitude), lng: parseFloat(longitude)});
  };

 const [selectedItems, setSelectedItems] = useState([]);
  const handleCheckboxChange = (id, isChecked) => {
    setSelectedItems((prev) =>
      isChecked
        ? [...prev, id]
        : prev.filter((itemId) => itemId !== id)
    );
  };

const handleGenerateExcel = () => {
  let id_E = entrepriseType === "1" ? "" : localS[0] || "";
  let listAnomalies_bacExcel = [];

  if (filteredDrivers.length > 0) {
   // setIsLoading(true);

    for (let i = 0; i < filteredDrivers.length; i++) {
      const item = filteredDrivers[i];
      if(item.createdAtBegin !== null && item.createdAtBegin !== undefined ){
          item.start = moment.utc(item.createdAt).format("HH:mm:ss");
        }
      if(item.createdAtEnd !== null && item.createdAtEnd !== undefined ) {
         item.end = moment.utc(item.updatedAt).format("HH:mm:ss");
      }
      listAnomalies_bacExcel.push(item);
    }

    let body = {
      listPointageExcel:listAnomalies_bacExcel,
      id_E: id_E,
    };
    fetch(api + '/collects/excel', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 200 && data.url) {
          const anchor = document.createElement('a');
          anchor.href = data.url;
          //anchor.download = 'anomalies.xlsx';
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
        } else {
          console.error("Erreur : L'URL du fichier Excel est invalide.");
        }
      })
      .catch(error => console.error("Erreur lors de la génération du fichier Excel :", error))
      .finally(() => setIsLoading(false));
  }
};

   const handleDateChange = (e) => {
    setSelectedDates({ ...selectedDates, [e.target.name]: e.target.value });
  };

  function formatage_date(imput_date){
    let ladate = imput_date;
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1).toString().padStart(2,'0')+'-'+ladate.getDate().toString().padStart(2,'0')
  }

   function less_day_date(date, nbr){
    let dateStart = new Date(date);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() - nbr));
    return formatage_date(dateEnd);
  }

  function add_day_date(date, nbr){
    let dateStart = new Date(date);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() + nbr));
    return formatage_date(dateEnd);
  }
    function date_aujour_d_hui(){
    let ladate = new Date();
    return ladate.getFullYear()+'-'+(ladate.getMonth()+1).toString().padStart(2,'0')+'-'+ladate.getDate().toString().padStart(2,'0')
  }
  function traitement_semaine(aujourdhui){
    let ladate = new Date();
    let position = ladate.getDay();
    let debut_semaine =  less_day_date(aujourdhui, position);
    let fin_semaine = add_day_date(debut_semaine,6);
    return {debut_semaine, fin_semaine}
  }

  function traitement_mois(aujourdui){
    let mois = aujourdui.slice(5,7);
    let annees = aujourdui.slice(0,4);
    let nbrjrsmoi = new Date(parseInt(annees), parseInt(mois), 1);
    let debut_mois = aujourdui.slice(0,8)+'01';
    let fin_mois = aujourdui.slice(0,8)+(nbrjrsmoi.getUTCDate());
    return {debut_mois, fin_mois};
  }
 function date_lendemain(){
    const today = new Date();
    const tomorrow = new Date();
    return tomorrow.setDate(today.getDate()-1);
  }
  useEffect(() => {
    const blob = async () => {
    const aujourdhui = date_aujour_d_hui();

    let dateStart = new Date(aujourdhui);
    let dateEnd = new Date(new Date().setDate(dateStart.getDate() - 1));
    const hier = formatage_date(dateEnd);

    const { debut_semaine, fin_semaine } = traitement_semaine(aujourdhui);
    const { debut_mois, fin_mois } = traitement_mois(aujourdhui);
    let basta= {...selectedDates, aujourdhui, hier, debut_semaine, fin_semaine, debut_mois, fin_mois };
    const entrepriseId = localS[0];
      if(entrepriseId === "12"){
        basta = {...basta, Hdebut : '00:00', Hfin : '23:59', debut: aujourdhui, fin : aujourdhui}
      }else if(entrepriseId === "10"){
        let debut= moment.utc(date_lendemain()).format("yyyy-MM-DD");
       basta = {...basta, Hdebut : '17:00', Hfin : '16:00', debut, fin : aujourdhui}
    }else{
      basta = {...basta, Hdebut : '01:00', Hfin : '23:59', debut: aujourdhui, fin : aujourdhui};
    }
    setSelectedDates(basta);
    }
    blob()
  }, []);

  async function handleFilterByDate(start, end){
    let start_ = start;
    let end_ = end;
    if(selectedDates.Hdebut !== '' || selectedDates.Hfin !== ''){
      start_ = start + " " + selectedDates.Hdebut + ":00";
      end_ = end + " " + selectedDates.Hfin + ":00";
    }

 //   setIsLoading(true);
    setTimeout(()=>setIsLoading(false),10000);
    const response = entrepriseType === "1"
        ? await axios.post(api + `/collects/betweenTwoDates`, {
          startDate: start_,
          endDate: end_
        })
        : await axios.post(api + `/collects/betweenTwoDatesByEntreprise`, {
          entrepriseId: localS[0],
          startDate: start_,
          endDate: end_
        });

    setDrivers(response.data.data);
    setFilteredDrivers(response.data.data);
  }




  const handleFileChange = (e) => {
    setImage(e.target.files[0]);
  };

  const handleUpload = async (e) => {
    e.preventDefault();
    if (!image) {
      alert("Veuillez sélectionner une image !");
      return;
    }
if (objetEmail && objetEmail.trim() == "") {
            alert("L'objet de l'email ne pourrais être pas vide.");
            return;
        }

    const formData = new FormData();
    formData.append("image", image);

    try {
      const uploadResponse = await axios.post(api+"/upload-michely", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      if (uploadResponse.status === 200) {
          const { nameFile } = uploadResponse.data;
    let id_E = entrepriseType === "1" ? localS[0] : localStorage.getItem("entrepriseId");

          const insertData = {
            nameFile,
            objetEmail,
            entrepriseId: id_E,
          };

          const insertResponse = await axios.post(`${api}/insertlogo_indb`, insertData);
          if (insertResponse.data) {
            setObjetEmail(insertResponse.data.objetEmail);

            const body = { entrepriseId: id_E };
            const entrepriseResponse = await axios.post(`${api}/getentreprise`, body);
            if (entrepriseResponse.status === 200) {
              const { logo: logo, objetEmail: objetEmail } = entrepriseResponse.data;
              setUrlImage(`${api}/${logo}`);
              setObjetEmail(objetEmail);
            }
          }
        }
    } catch (error) {
      console.error("Erreur lors de l'upload :", error);
    }
  };


 

  return (
    <div  style={{ maxHeight: window.innerHeight - 100, overflowY: 'auto' }} className="driver-page">


      <Modal show={showModal} onHide={resetEmail}>
        <Modal.Header closeButton>
          <Modal.Title>{actionType === "add" ? "Ajout " : "Modifier"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>


          <input
            type="email"
            placeholder="Email"
            value={currentEmail.email || ""}
            onChange={(e) => setCurrentEmail({ ...currentEmail, email: e.target.value })}
            className="form-control mb-3"
          />
        <input
            type="text"
            placeholder="Decalage en nb d'heure"
            value={currentEmail.decalage || ""}
            onChange={(e) => setCurrentEmail({ ...currentEmail, decalage: e.target.value })}
            className="form-control mb-3"
          />
        <div style={{display:'flex', flexDirection:'row', alignItems:'center'}}><div style={{marginRight:'5px'}}>Heure d'envoi automatique : </div><input id="HeureEnvoi" type="time"
        value={currentEmail.HeureEnvoi}
        name="HeureEnvoi" onChange={(e)=>{
          setCurrentEmail({ ...currentEmail, HeureEnvoi: e.target.value })
        }} /></div>

          { entrepriseType === "1" && <select
            value={currentEmail.entrepriseId || ""}
            onChange={(e) => setCurrentEmail({ ...currentEmail, entrepriseId: e.target.value })}
            className="form-control"
          >
            <option value="">Select Entreprise</option>
            {entreprises.map(entreprise => (
              <option key={entreprise.id} value={entreprise.id}>{entreprise?.name}</option>
            ))}
          </select>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetEmail}>Close</Button>
          <Button variant="primary" onClick={handleAddOrUpdateEmail}>
            {actionType === "add" ? "Ajouter" : "Modifier"}
          </Button>
        </Modal.Footer>
      </Modal>


       <div style={{display:'flex', flexDirection:'row'}}>
          <button className="btn btn-sm btn-success" onClick={() => openModal()} >
          Ajouter un e-mail
        </button>

        <button style={{marginLeft:'10px'}} className="btn btn-sm btn-info" onClick={envoyerEmailManually} >
          Envoyer manuellement l'e-mail
        </button>


         <div>
        <label>
          <input
                    style={{marginLeft:'5px', marginRight:'5px'}}

            type="radio"
            value="option1"
            checked={selectedOption === "option1"}
            onChange={(event) => {
            setSelectedOption(event.target.value);
          }}
          />
          Mailjet
        </label>
      </div>
      <div>
        <label>
          <input
          style={{marginLeft:'5px', marginRight:'5px'}}
            type="radio"
            value="option2"
            checked={selectedOption === "option2"}
            onChange={(event) => {
    setSelectedOption(event.target.value);
  }}
          />
          Cron local
        </label>
      </div>


        </div>


        <div style={{ marginTop:'10px', marginBottom:'10px', overflowX: 'auto' }}>
      <table style={{ borderCollapse: 'collapse', width: '100%', textAlign: 'left' }}>
        <thead>
          <tr>
           {entrepriseType === '1' && <th style={headerStyle}></th>}
            <th style={headerStyle}>E-mail</th>
            <th style={headerStyle}>Décalage à soustraire en H</th>
            <th style={headerStyle}>Heure d'envoi</th>
            <th style={headerStyle}></th>
          </tr>
        </thead>
        {isLoading ? (
          <ClipLoader color={"#123abc"} loading={isLoading} size={50} />)
         : (<tbody>
          {emails.map((item, index)=>(
            <tr key={index} style={index % 2 === 0 ? rowStyleEven : rowStyleOdd}>
             {entrepriseType === '1' && <td style={cellStyle}>{item?.entreprise?.name}</td>}
              <td style={cellStyle}>{item.email}{item.email && <Button onClick={() => handleSendEmailUneSansRetardateur(item)} variant="info" size="sm" className="ms-2"><i className="fa fa-paper-plane"></i></Button>}</td>
              <td style={cellStyle}>{item?.decalage && item.decalage + 'h de décalage.'}</td>
              <td style={cellStyle}>{item?.HeureEnvoi && item.HeureEnvoi }</td>
              <td>
              <div style={{display:'flex', flexDirection:'row'}}>

          <Button onClick={() => openModal(item)} variant="warning" size="sm"><i className="fa fa-edit"></i></Button>

        <Button onClick={() => handleDeleteEmail(item.id)} variant="danger" size="sm" className="ms-2"><i className="fa fa-trash"></i></Button>

        <Button onClick={() => handleSendEmailParUneSeulemnt(item)} variant="info" size="sm" className="ms-2"><i className="fa fa-paper-plane"></i></Button>

        </div>
        </td>


            </tr>
          ))}
        </tbody>)}
      </table>
    </div>


    
    { entrepriseType !== "1" && <div> <img src={urlImage} alt="Aperçu" style={{ maxWidth: "200px", marginBottom: "10px" }} />
        <div>
        <input
              type="text"
              value={objetEmail}
              onChange={(e) => setObjetEmail(e.target.value)}
              placeholder="Objet Email"
            />
        <form onSubmit={handleUpload}>
          <input type="file" accept="image/*" onChange={handleFileChange} />
          <button type="submit" className="btn btn-sm btn-info">Envoyer</button>
        </form>
      </div></div>}

    </div>
  );
}
