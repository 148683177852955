

 //export const api = 'http://localhost:3005';
 export const api = 'https://app-collect.axesinnovations.net';













