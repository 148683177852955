import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import ClipLoader from "react-spinners/ClipLoader";
import axios from "axios";
import { api } from '../config/constant.js';
import DataTable,{createTheme} from 'react-data-table-component';



createTheme('solarized', {
  divider: {
    default: '#0d6efd',
  },
});

export default function Driver({localS}) {
  const [drivers, setDrivers] = useState([]);
  const [filteredDrivers, setFilteredDrivers] = useState([]);
  const [entreprises, setEntreprises] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentDriver, setCurrentDriver] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState("");
  const [searchText, setSearchText] = useState("");
  const [entrepriseType, setentrepriseType] = useState(localS[2]);
  //const entrepriseType = localStorage.getItem("entrepriseType");

  useEffect(() => {
    fetchEntreprises();
    loadDrivers();
  }, []);


  const loadDrivers = () => {
    setIsLoading(true);
    const entrepriseId = JSON.parse(localS[0]);
    const url = entrepriseType === "1"
      ? `${api}/ripeurs/getAll`
      : `${api}/ripeurs/getAllByEntreprise`;

    const options = entrepriseType === "1"
      ? { method: "GET" }
      : {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ entrepriseId })
        };

    fetch(url, options)
      .then(response => response.json())
      .then(data => {
                const michely = data.data.reverse().map((item, index) => ({
        ...item,
        michely: `${index + 1}`,
      }));

        setDrivers(michely);
        setFilteredDrivers(michely); // Initialize filteredDrivers with all data
        setIsLoading(false);
      })
      .catch(error => {
        console.error("Error fetching drivers:", error);
        setIsLoading(false);
      });
  };

  const fetchEntreprises = () => {
    axios.get(api + "/entreprises/getAll")
      .then(response => setEntreprises(response.data.data));
  };
const handleSearch = (e) => {
  const searchValue = e.target.value;
  setSearchText(searchValue);
  const filteredData = drivers.filter((driver) => {
    // Vérifie si chaque champ est défini avant d'appeler `toLowerCase`
    const firstName = driver.firstname ? driver.firstname.toLowerCase() : "";
    const lastName = driver.lastname ? driver.lastname.toLowerCase() : "";
    const immatricule = driver.immatricule ? driver.immatricule.toLowerCase() : "";

    return (
      firstName.includes(searchValue.toLowerCase()) ||
      lastName.includes(searchValue.toLowerCase()) ||
      immatricule.includes(searchValue.toLowerCase())
    );
  });
  setFilteredDrivers(filteredData);
};

  const handleAddOrUpdateDriver = () => {
    if (
      !currentDriver.firstname ||
      !currentDriver.lastname ||
      !currentDriver.immatricule
     // !currentDriver.entrepriseId
    ) {
      alert("Veuillez remplir tous les champs.");
      return;
    }
    if(!currentDriver.entrepriseId){
        currentDriver.entrepriseId = localS[0]
    }
    setIsLoading(true);
    const url = actionType === "add"
      ? api + "/ripeurs/add"
      : api + "/ripeurs/update";

    fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(currentDriver)
    })
    .then(response => response.json())
    .then(() => {
      loadDrivers();
      resetForm();
    })
    .catch(error => {
      console.error("Error saving driver:", error);
      setIsLoading(false);
    });
  };
const handleDeleteDriver = (id) => {
  const confirmed = window.confirm("Êtes-vous sûr de vouloir supprimer ?");

  if (confirmed) {
    setIsLoading(true);
    fetch(api + '/ripeurs/delete', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id })
    })
    .then(response => response.json())
    .then(() => {
      loadDrivers();
    })
    .catch(error => {
      console.error("Error deleting driver:", error);
      setIsLoading(false);
    });
  }
};


  const resetForm = () => {
    setShowModal(false);
    setCurrentDriver({});
    setIsLoading(false);
  };

  const openModal = (driver = {}) => {
    setActionType(driver.id ? "update" : "add");
    setCurrentDriver(driver);
    setShowModal(true);
  };

  const oa = [
    {
    name: "",
    selector: (row) => row.michely, // Add 1 to make it 1-based
    width: "60px",
  },
  //  { name: "ID", selector: row => row.id, sortable: true },
      { name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Identification</div>, selector: row => row.immatricule, sortable: true },

    { name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Nom</div>, selector: row => row.firstname, sortable: true },
    { name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Prénoms</div>, selector: row => row.lastname, sortable: true },
    {
      name: "Actions",
      cell: row => (
        <>
          <Button onClick={() => openModal(row)} variant="warning" size="sm"><i className="fa fa-edit"></i></Button>
          <Button onClick={() => handleDeleteDriver(row.id)} variant="danger" size="sm" className="ms-2"><i className="fa fa-trash"></i></Button>
        </>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
    const copiedColumns = [...oa]; // Copie de `oa`
  if(entrepriseType === '1')
  copiedColumns.splice(1, 0, {
    name: <div style={{ maxWidth: '150px', whiteSpace: 'normal', wordWrap: 'break-word' }}>Entreprise</div>,
    selector: row => row?.entreprise?.name,
    sortable: true,
    wrap: true,
  });

  const columns = entrepriseType === "1" ? copiedColumns : oa;


 const paginationOptions = {
    rowsPerPageText: "Lignes par page", // Texte traduit
    rangeSeparatorText: "de", // Texte entre les numéros
    selectAllRowsItem: false, // Option pour "Sélectionner tout" (désactivé ici)
    selectAllRowsItemText: "Tous", // Texte si activé
  };


  return (
  <div  style={{ maxHeight: window.innerHeight - 100, overflowY: 'auto' }} className="driver-page">
      <Button onClick={() => openModal()} variant="primary" className="mb-3">Ajout</Button>

      <input
        type="text"
        placeholder="Rechercher..."
        value={searchText}
        onChange={handleSearch}
        className="form-control mb-3 border-primary"
      />

      <div className="data-table">
        {isLoading ? (
          <ClipLoader color={"#123abc"} loading={isLoading} size={50} />
        ) : (
          <DataTable
            columns={columns}
            data={filteredDrivers}  // Utilisation des données filtrées
            pagination
            paginationComponentOptions={paginationOptions} // Texte personnalisé
            theme="solarized"
              paginationPerPage={30} // Réglage par défaut à 30
            paginationRowsPerPageOptions={[10, 30, 50]}
            fixedHeader
            fixedHeaderScrollHeight={`${(window.innerHeight/2) +(window.innerHeight/6)}px`}

          />
        )}
      </div>

      <Modal show={showModal} onHide={resetForm}>
        <Modal.Header closeButton>
          <Modal.Title>{actionType === "add" ? "Ajout" : "Modifier"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder="Firstname"
            value={currentDriver.firstname || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, firstname: e.target.value })}
            className="form-control mb-3"
          />
          <input
            type="text"
            placeholder="Lastname"
            value={currentDriver.lastname || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, lastname: e.target.value })}
            className="form-control mb-3"
          />
          <input
            type="text"
            placeholder="Immatricule"
            value={currentDriver.immatricule || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, immatricule: e.target.value })}
            className="form-control mb-3"
          />
          {entrepriseType === "1" && <select
            value={currentDriver.entrepriseId || ""}
            onChange={(e) => setCurrentDriver({ ...currentDriver, entrepriseId: e.target.value })}
            className="form-control"
          >
            <option value="">Select Entreprise</option>
            {entreprises.map(entreprise => (
              <option key={entreprise.id} value={entreprise.id}>{entreprise.name}</option>
            ))}
          </select>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={resetForm}>Close</Button>
          <Button variant="primary" onClick={handleAddOrUpdateDriver}>
            {actionType === "add" ? "Ajouter" : "Modifier"}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
